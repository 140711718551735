
import {
  defineComponent,
  ref,
  reactive,
  toRefs,
  onMounted,
  Ref,
  onUnmounted,
} from "vue";
import { useRouter, useRoute } from "vue-router";
import ModelLib from "@/components/ModelLib/index.vue";
export default defineComponent({
  components: {
    ModelLib,
  },
  setup() {
    const router = useRouter();
    const route = useRoute();
    onMounted(() => {});
    return {};
  },
});
