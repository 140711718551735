<template>
  <div>
    <van-index-bar :index-list="letterArr" highlight-color="#FFB500">
      <div v-for="letter in letterArr" :key="letter">
        <van-index-anchor :index="letter">
          <div class="anchor-text">
            <span class="anchor-letter">{{ letter }}</span>
          </div>
        </van-index-anchor>
        <div
          class="model-item"
          v-for="model in brandObj[letter]"
          :key="model.id"
          @click="onBrandSelect(model)"
        >
          <van-image
            round
            fit="cover"
            class="model-avatar"
            :src="model.avatar"
          />
          <div class="model-text">{{ model.name }}</div>
        </div>
      </div>
    </van-index-bar>
    <model-drawer
      ref="drawerRef"
      :type="type"
      @on-select-success="onSelectSuccess"
    />
  </div>
</template>

<script>
import HttpRequest from "@/network/HttpRequest";
import ModelDrawer from "./ModelDrawer";

export default {
  name: "ModelLib",
  components: {
    ModelDrawer,
  },
  props: {
    type: {
      type: String,
      default: "model",
    },
  },
  data() {
    return {
      letterArr: [],
      brandObj: {},
      lastSelectBrandId: "",
    };
  },
  methods: {
    async getBrandList() {
      const toast = this.$toast.loading({
        message: "加载中...",
        forbidClick: true,
        duration: 0,
      });
      try {
        const modelResult = await HttpRequest({
          url: "yiautos-models-unify/brand/group/firstLetter",
          methods: "GET",
        });
        if (modelResult.code === 200) {
          this.brandObj = modelResult.data;
          this.letterArr = Object.keys(this.brandObj).sort((a, b) =>
            a >= b ? 1 : -1
          );
        } else {
          throw new Error(modelResult.msg);
        }
        toast.clear();
      } catch (e) {
        toast.clear();
        this.$toast.fail(e.message);
      }
    },

    onBrandSelect(brand) {
      const drawerRef = this.$refs["drawerRef"];
      if (this.type === "brand") {
        this.$emit("on-select-success", brand);
        return;
      }
      this.lastSelectBrandId = brand.id;
      drawerRef.open(brand);
    },

    // 返回车系与车型时附带品牌Id
    onSelectSuccess(target) {
      console.log("即将向上抛出事件 ==>", target, this.lastSelectBrandId);
      this.$emit("on-select-success", {
        ...target,
        brand_id: this.lastSelectBrandId,
      });
    },
  },
  mounted() {
    this.getBrandList();
  },
  // 为兼容Vue2项目, 组件库不做compositionApi
  // setup() {},
};
</script>

<style lang="less" scoped>
@import "~@/styles/common";

.ymc-modellib-box {
  width: 100%;
  height: 100%;
  background-color: white;
  overflow-y: auto;
}

.anchor-text {
  height: 1.2rem;
  line-height: 1.2rem;
  background-color: white;
  vertical-align: middle;
  text-align: left;
  .anchor-letter {
    .font-medium(0.36rem, @color-theme);
  }
}

.model-item {
  height: 1.1rem;
  background-color: white;
  width: 100%;
  box-sizing: border-box;
  padding: 0 0.4rem;
  .flexible(row, center, flex-start);

  &:active {
    opacity: 0.8;
    background-color: #fafafa;
  }

  .model-avatar {
    width: 0.8rem;
    height: 0.8rem;
    border-radius: 0.4rem;
    margin-right: 0.7rem;
  }

  .model-text {
    width: 0;
    height: 1.1rem;
    flex: 1;
    line-height: 1.1rem;
    vertical-align: middle;
    .font-regular(0.28rem, #000000);
    border-bottom: 0.02rem solid #f0f0f0;
    text-align: left;
  }
}
</style>
