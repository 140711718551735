<template>
  <div>
    <van-popup key="series-drawer"
               v-model:show="showSeriesDrawer"
               position="right"
               class="drawer"
               style="width: 6.1rem; height: 100%"
               lock-scroll>
      <div class="list-container">
        <div class="list-header">
          <span class="list-header-title">{{ brandName }}-选择车系</span>
          <!-- <span class="list-header-back" @click="closeSeries">返回</span> -->
        </div>
        <div class="list-scroller">
          <div v-for="firm in firmsList"
               :key="firm.id">
            <div class="section-header">{{ firm.firmName }}</div>
            <div v-for="series in firm.seriesList"
                 :key="series.id"
                 class="series-item"
                 style="height: 1.24rem"
                 @click="onSeriesSelect(series)">
              <van-image class="series-item-avatar"
                         fit="cover"
                         :src="series.avatar" />
              <div class="series-item-message">
                <span>{{ series.name }}</span>
                <span>指导价: <span>{{ series.indicativePrice }}</span></span>
              </div>
            </div>
          </div>
          <div style="height: 0.8rem" />
        </div>
      </div>
    </van-popup>
    <van-popup key="model-drawer"
               v-model:show="showModelDrawer"
               position="right"
               class="drawer"
               style="width: 6.1rem; height: 100%"
               lock-scroll>
      <div class="list-container">
        <div class="list-header">
          <span class="list-header-title">{{ seriesName }}-选择车型</span>
          <!-- <span class="list-header-back" @click="closeModels">返回</span> -->
        </div>
        <div class="list-scroller">
          <div v-for="year in modelList"
               :key="year.year">
            <div class="section-header">{{ year.year }}</div>
            <div v-for="model in year.models"
                 :key="model.id"
                 class="series-item"
                 @click="onModelSelect(model)">
              <div class="series-item-model-message">
                <span>{{ model.name }}</span>
                <span>指导价: <span>{{ model.indicativePrice }}万元</span></span>
              </div>
            </div>
          </div>
          <div style="height: 0.8rem" />
        </div>
      </div>
    </van-popup>
  </div>
</template>

<script>
import HttpRequest from "@/network/HttpRequest";
import store from "@/store/index.ts";
export default {
  name: "ModelDrawer",
  props: {
    type: {
      type: String,
      default: "model",
    },
  },
  data () {
    return {
      brandName: "品牌",
      seriesName: "车系",
      showModelDrawer: false,
      showSeriesDrawer: false,
      firmsList: [],
      modelList: [],
    };
  },
  methods: {
    async onModelSelect (model) {
      this.showModelDrawer = false;
      this.showSeriesDrawer = false;
      this.$router.go(-1);
      store.commit("setCarModel", model);
    },
    async open (brand) {
      this.brandName = brand.name;
      const toast = this.$toast.loading({
        message: "加载中...",
        forbidClick: true,
        duration: 0,
      });
      try {
        const firmRes = await HttpRequest({
          url: `yiautos-models-unify/series/listPrice/byBrand/${brand.id}?status=0`,
          method: "GET",
        });
        if (firmRes.code !== 200) {
          throw new Error(firmRes.msg);
        }
        const seriesList = firmRes.data;
        const firmMap = {};
        seriesList.forEach((seriesData) => {
          const { firmId, firmName } = seriesData;
          const firmData = firmMap[firmId];
          if (firmData) {
            firmData.seriesList.push(seriesData);
          } else {
            firmMap[firmId] = {
              firmId,
              firmName,
              seriesList: [seriesData],
            };
          }
        });
        const seriesSortedList = Object.keys(firmMap).map((key) => {
          return firmMap[key];
        });
        this.firmsList = seriesSortedList;
        console.log("排序后的车系列表 ==>", seriesSortedList);
        toast.clear();
        this.showSeriesDrawer = true;
      } catch (e) {
        console.log("获取车系列表失败 ==>", e);
        toast.clear();
        this.$toast.fail(e.message);
      }
    },

    closeSeries () {
      this.showSeriesDrawer = false;
    },

    closeModels () {
      this.showModelDrawer = false;
    },

    async onSeriesSelect (series) {
      if (this.type === "series") {
        this.showModelDrawer = false;
        this.showSeriesDrawer = false;
        this.onSelectSuccess(series);
        return;
      }
      this.seriesName = series.name;
      const toast = this.$toast.loading({
        message: "加载中...",
        forbidClick: true,
        duration: 0,
      });
      try {
        const modelResult = await HttpRequest({
          url: `yiautos-models-unify/models/group/productiveYear/bySeries/${series.id}?status=0`,
          method: "GET",
        });
        if (modelResult.code === 200) {
          this.modelList = Object.keys(modelResult.data)
            .sort((a, b) => b - a)
            .map((key) => {
              return {
                year: key,
                models: modelResult.data[key],
              };
            });
        } else {
          throw new Error(modelResult.message);
        }
        toast.clear();
        this.showModelDrawer = true;
      } catch (e) {
        toast.clear();
        this.$toast.fail(e.message);
      }
    },
  },
  mounted () { },
};
</script>

<style lang="less" scoped>
@import "~@/styles/common";

.drawer {
  width: 6.1rem;
  height: 100%;
}

.list-container {
  height: 100%;
  width: 100%;
}

.list-header {
  padding: 0 0.4rem;
  box-sizing: border-box;
  height: 1rem;
  width: 100%;
  .flexible(row, center, space-between);

  &-title {
    .font-medium(0.36rem, #242b30);
  }
  &-back {
    padding: 0.1rem;
    .font-regular(0.28rem, #242b30);
    &:active {
      opacity: 0.8;
    }
  }
}
.list-scroller {
  box-sizing: border-box;
  padding: 0 0.4rem;
  height: calc(~"100% - 1rem");
  width: 100%;
  overflow-y: scroll;
}
.section-header {
  width: 100%;
  height: 0.6rem;
  background: rgba(241, 241, 241, 1);
  border-radius: 0.04rem;
  line-height: 0.6rem;
  text-align: center;
  .font-regular(0.28rem, #242b30);
  margin: 0.24rem 0;
}
.series-item {
  width: 100%;
  // height: 72rem;
  .flexible(row, center, flex-start);
  border-bottom: 0.02rem solid #f0f0f0;

  &:active {
    opacity: 0.8;
  }

  &-avatar {
    width: 1.4rem;
    height: 0.94rem;
    margin-right: 0.4rem;
  }

  &-model-message {
    box-sizing: border-box;
    padding: 0.16rem 0rem;
    .flexible(column, flex-start, space-between);
    // width: 100%;
    // height: 47rem;
    text-align: left;

    span {
      width: 100%;
      text-align: left;
      overflow: hidden;
      // .single-line;
    }

    :first-child {
      text-align: left;
      .font-regular(0.28rem, #242b30);
    }

    :last-child {
      text-align: left;
      padding-top: 0.2rem;
      .font-regular(0.24rem, rgba(0, 0, 0, 0.28));
      :first-child {
        .font-medium(0.24rem, #F03A21);
      }
    }
  }

  &-message {
    box-sizing: border-box;
    padding: 0.06rem 0;
    .flexible(column, flex-start, space-between);
    // width: 170rem;
    // height: 47rem;

    span {
      width: 100%;
      overflow: hidden;
      .single-line;
    }

    :first-child {
      .font-regular(0.28rem, #242b30);
    }

    :last-child {
      padding-top: 0.1rem;
      .font-regular(0.24rem, rgba(0, 0, 0, 0.28));
      :first-child {
        .font-medium(0.24rem, #F03A21);
      }
    }
  }
}
</style>
